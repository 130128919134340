<template>
  <div class="body_wrap">

<!-- backtotop - start -->
<div class="backtotop">
  <a href="#" class="scroll">
    <i class="far fa-arrow-up"></i>
  </a>
</div>
<!-- backtotop - end -->

<!-- preloader - start -->
<!-- <div id="preloader"></div> -->
<!-- preloader - end -->

<!-- header_section - start
================================================== -->
<header class="header_section">
        <div class="header_middle">
          <div class="container">
            <div class="row align-items-center">
              <div class="col col-lg-3 col-5">
                <div class="brand_logo">
                  <a class="brand_link" href="/">
                    <img src="./assets/logo.png">
                  </a>
                </div>
              </div>
              <div class="col col-lg-6 col-2">
                <nav class="main_menu navbar navbar-expand-lg">
                  <div class="main_menu_inner collapse navbar-collapse justify-content-center" id="main_menu_dropdown">
                    <ul class="main_menu_list ul_li">
                      <!-- <li>
                        <a href="/">Home</a>
                      </li> -->
                      <!-- <li>
                        <a href="/register">register</a>
                      </li> -->
                      <!-- <li><a href="/register">Sign In</a></li>
                      <li><a style="cursor: pointer;" @click="tiaozhuanAccount()">Shopping Cart</a></li> -->
                      <li><a href="/#1">HOT ITEMS</a></li>
                      <li><a href="/#2">NEW ARRIVALS</a></li>
                      <li><a href="/#3">BEST SELLER</a></li>
                    </ul>
                  </div>
                </nav>
              </div>

              <div class="col col-lg-3 col-5">
                <ul class="header_icons_group ul_li_right">
                  <li>
                    <button class="mobile_menu_btn navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_menu_dropdown" aria-controls="main_menu_dropdown" aria-expanded="false" aria-label="Toggle navigation">
                      <i class="fal fa-bars"></i>
                    </button>
                  </li>
                  <li><a style="cursor: pointer;" @click="tiaozhuanAccount()"><i class="fa-thin fa-cart-shopping"></i></a></li>
                  <li><a style="cursor: pointer;" @click="tiaozhuanAccount2()"><i class="fa-regular fa-user"></i></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="header_bottom">
          <div class="container">
            <div class="row align-items-center" style="padding:10px 0px;">
              <div class="col col-md-10 col-10">
                <div class="allcategories_dropdown">
                  <h2 class="title_text" style="color:#fff;"><i class="fa-duotone fa-fire"></i> Technology e-commerce, worry-free shopping!</h2>
                </div>
              </div>

              <div class="col col-md-2 col-2">
                <!-- <a style="cursor: pointer;" @click="tiaozhuanAccount()">
                <button type="button" class="cart_btn">
                  <span class="cart_icon">
                    <i class="icon icon-ShoppingCart"></i>
                  </span>
                </button>
              </a> -->
              </div>
            </div>
          </div>
        </div>
      </header>
<!-- header_section - end
================================================== -->

<!-- main body - start
================================================== -->
<main>

  <!-- sidebar cart - start
  ================================================== -->
  <div class="sidebar-menu-wrapper">
    <div class="cart_sidebar">
      <button type="button" class="close_btn"><i class="fal fa-times"></i></button>

      <!-- <ul class="cart_items_list ul_li_block mb_30 clearfix" v-if="pageData.list">
        <li v-for="(item, index) in pageData.list.length" :key="index">
          <div class="item_image">
            <img src="assets/images/cart/cart_img_1.jpg" alt="Getyootech - Gadgets Ecommerce Site Template">
          </div>
          <div class="item_content">
            <h4 class="item_title">Yellow Blouse</h4>
            <span class="item_price">$30.00</span>
          </div>
          <button type="button" class="remove_btn"><i class="fal fa-trash-alt"></i></button>
        </li>
      </ul>

      <ul class="total_price ul_li_block mb_30 clearfix">
        <li>
          <span>Subtotal:</span>
          <span>$90</span>
        </li>
        <li>
          <span>Vat 5%:</span>
          <span>$4.5</span>
        </li>
        <li>
          <span>Discount 20%:</span>
          <span>- $18.9</span>
        </li>
        <li>
          <span>Total:</span>
          <span>$75.6</span>
        </li>
      </ul> -->

      <ul class="btns_group ul_li_block clearfix">
        <li><a class="btn btn_primary" href="/cart">View Cart</a></li>
        <li><a class="btn btn_secondary" href="checkout.html">Checkout</a></li>
      </ul>
    </div>

    <div class="cart_overlay"></div>
  </div>
  <!-- sidebar cart - end
  ================================================== -->

  <!-- product quick view modal - start
  ================================================== -->
  <div class="modal fade" id="quickview_popup" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalToggleLabel2">Product Quick View</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="product_details">
            <div class="container">
              <div class="row">
                <div class="col col-lg-6">
                  <div class="product_details_image p-0">
                    <img src="assets/images/details/product_details_img_1.png" alt="Getyootech - Gadgets Ecommerce Site Template">
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="product_details_content">
                    <h2 class="item_title">CURREN 8109 Watches</h2>
                    <p>
                      It is a long established fact that a reader will be distracted eget velit. Donec ac tempus ante. Fusce ultricies massa massa. Fusce aliquam, purus eget sagittis vulputate
                    </p>
                    <div class="item_review">
                      <ul class="rating_star ul_li">
                        <li><i class="fa-solid fa-star"></i></li>
                        <li><i class="fa-solid fa-star"></i></li>
                        <li><i class="fa-solid fa-star"></i></li>
                        <li><i class="fa-solid fa-star"></i></li>
                        <li><i class="fa-solid fa-star"></i></li>
                      </ul>
                      <span class="review_value">3 Rating(s)</span>
                    </div>
                    <div class="item_price">
                      <span>MXN$620.00</span>
                      <del>MXN$720.00</del>
                    </div>

                    <hr>

                    <div class="item_attribute">
                      <h3 class="title_text">Options <span class="underline"></span></h3>
                      <form action="#">
                        <div class="row">
                          <div class="col col-md-6">
                            <div class="select_option clearfix">
                              <h4 class="input_title">Size *</h4>
                              <select>
                                <option data-display="- Please select -">Choose A Option</option>
                                <option value="1">Some option</option>
                                <option value="2">Another option</option>
                                <option value="3" disabled>A disabled option</option>
                                <option value="4">Potato</option>
                              </select>
                            </div>
                          </div>
                          <div class="col col-md-6">
                            <div class="select_option clearfix">
                              <h4 class="input_title">Color *</h4>
                              <select>
                                <option data-display="- Please select -">Choose A Option</option>
                                <option value="1">Some option</option>
                                <option value="2">Another option</option>
                                <option value="3" disabled>A disabled option</option>
                                <option value="4">Potato</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <span class="repuired_text">Repuired Fiields *</span>
                      </form>
                    </div>

                    <div class="quantity_wrap">
                      <form action="#">
                        <div class="quantity_input">
                          <button type="button" class="input_number_decrement">
                            <i class="fal fa-minus"></i>
                          </button>
                          <input class="input_number" type="text" value="1">
                          <button type="button" class="input_number_increment">
                            <i class="fal fa-plus"></i>
                          </button>
                        </div>
                      </form>

                      <div class="total_price">
                        Total: MXN$620,99
                      </div>
                    </div>

                    <ul class="default_btns_group ul_li">
                      <li><a class="addtocart_btn" href="shop_details.html">Add To Cart</a></li>
                      <li><a href="#!"><i class="fa-solid fa-arrows-rotate"></i></a></li>
                      <li><a href="#!"><i class="fas fa-heart"></i></a></li>
                    </ul>

                    <ul class="default_share_links ul_li">
                      <li>
                        <a class="facebook" href="#!">
                          <span><i class="fab fa-facebook-square"></i> Like</span>
                          <small>10K</small>
                        </a>
                      </li>
                      <li>
                        <a class="twitter" href="#!">
                          <span><i class="fab fa-twitter-square"></i> Tweet</span>
                          <small>15K</small>
                        </a>
                      </li>
                      <li>
                        <a class="google" href="#!">
                          <span><i class="fab fa-google-plus-square"></i> Google+</span>
                          <small>20K</small>
                        </a>
                      </li>
                      <li>
                        <a class="share" href="#!">
                          <span><i class="fas fa-plus-square"></i> Share</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- product quick view modal - end
  ================================================== -->

  <!-- breadcrumb_section - start
  ================================================== -->
  <div class="breadcrumb_section">
    <div class="container">
      <ul class="breadcrumb_nav ul_li">
        <li><a href="/">Home</a></li>
        <li>Product Details</li>
      </ul>
    </div>
  </div>
  <!-- breadcrumb_section - end
  ================================================== -->

  <!-- product_details - start
  ================================================== -->
  <section class="product_details section_space pb-0">
    <div class="container">
      <div class="row">
        <div class="col col-lg-6">
          <div class="product_details_image">
            <div class="details_image_carousel">
              <!-- <div class="slider_item">
                <img :src="'assets/images/' + goodsData.productInfo.image" alt="Getyootech - Gadgets Ecommerce Site Template">
              </div> -->
              <div class="slider_item" style="border-radius: 8px;overflow: hidden;" v-for="(item, index) in JSON.parse(goodsData.productInfo.sliderImage)" :key="index">
                <img style="margin:auto;" :src="'assets/images/' + item.replace(/\.png$/, '.jpg')" alt="Getyootech - Gadgets Ecommerce Site Template">
              </div>
            </div>

            <div class="details_image_carousel_nav">
              <!-- <div class="slider_item">
                <img :src="'assets/images/' + goodsData.productInfo.image" alt="Getyootech - Gadgets Ecommerce Site Template">
              </div> -->
              <div class="slider_item" style="border-radius: 8px;overflow: hidden;" v-for="(item, index) in JSON.parse(goodsData.productInfo.sliderImage)" :key="index">
                <img style="margin:auto;" :src="'assets/images/' + item.replace(/\.png$/, '.jpg')" alt="Getyootech - Gadgets Ecommerce Site Template">
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="product_details_content" v-if="goodsData.productInfo">
            <h2 class="item_title">{{ goodsData.productInfo.storeName }}</h2>
            <div>
              <!-- <p v-if="goodsData.productInfo.id === 3">
              {{ goodsData.productInfo.storeInfo }}
            </p> -->
            </div>
            <div class="item_review"> 
              <ul class="rating_star ul_li">
                <li><i class="fa-solid fa-star"></i></li>
                <li><i class="fa-solid fa-star"></i></li>
                <li><i class="fa-solid fa-star"></i></li>
                <li><i class="fa-solid fa-star"></i></li>
                <li><i class="fa-solid fa-star"></i></li>
              </ul>
              <span class="review_value">3 Rating(s)</span>
            </div>
            <div class="item_price" v-if="goodsData.productInfo">
              <span>MXN${{ goodsData.productInfo.price }}</span>
              <!-- <del>MXN${{ goodsData.productInfo.price }}</del> -->
            </div>

            <hr>

            <div class="item_attribute">
              <h3 class="title_text">Options <span class="underline"></span></h3>
            </div>

            <div class="quantity_wrap">
              <!-- <form action="#">
                              <div class="quantity_input">
                                <button @click="jianshao" type="button" class="input_number_decrement">
                                  <i class="fal fa-minus"></i>
                                </button>
                                <input class="input_number" type="text" value="1">
                                <button type="button" class="input_number_increment">
                                  <i class="fal fa-plus"></i>
                                </button>
                              </div>
                            </form> <br> -->
              <div class="total_price" v-if="goodsData.productInfo">
                Total: MXN${{ goodsData.productInfo.price }}
              </div>
            </div>

            <ul class="default_btns_group ul_li">
              <li><a class="addtocart_btn" @click="clickAdd()">Add To Shopping Cart</a></li>
              <!-- <li><a @click="init()"><i class="fa-solid fa-arrows-rotate"></i></a></li> -->
            </ul>

            <ul class="default_share_links ul_li">
              <li>
                <a class="facebook" href="#!">
                  <span><i class="fab fa-facebook-square"></i> Like</span>
                  <small>10K</small>
                </a>
              </li>
              <li>
                <a class="twitter" href="#!">
                  <span><i class="fab fa-twitter-square"></i> Tweet</span>
                  <small>15K</small>
                </a>
              </li>
              <li>
                <a class="google" href="#!">
                  <span><i class="fab fa-google-plus-square"></i> Google+</span>
                  <small>20K</small>
                </a>
              </li>
              <li>
                <a class="share" href="#!">
                  <span><i class="fas fa-plus-square"></i> Share</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="details_information_tab">
        <ul class="tabs_nav nav ul_li" role=tablist>
          <li role="presentation">
            <button class="active" data-bs-toggle="tab" data-bs-target="#description_tab" type="button" role="tab" aria-controls="description_tab" aria-selected="true">
              Description
            </button>
          </li>
        </ul>
        <div class="tab-content">
          <div class="tab-pane fade show active" id="description_tab" role="tabpanel" v-if="goodsData.productInfo">
            <p>
              {{ goodsData.productInfo.storeInfo }}
            </p>
          </div>

          <div class="tab-pane fade" id="additional_information_tab" role="tabpanel">
            <p>
              Return into stiff sections the bedding was hardly able to cover it and seemed ready to slide off any moment. His many legs, pitifully thin compared with the size of the rest of him, waved about helplessly as he looked what's happened to me he thought It wasn't a dream. His room, a proper human room although a little too small
            </p>

            <div class="additional_info_list">
              <h4 class="info_title">Additional Info</h4>
              <ul class="ul_li_block">
                <li>No Side Effects</li>
                <li>Made in USA</li>
              </ul>
            </div>

            <div class="additional_info_list">
              <h4 class="info_title">Product Features Info</h4>
              <ul class="ul_li_block">
                <li>Compatible for indoor and outdoor use</li>
                <li>Wide polypropylene shell seat for unrivalled comfort</li>
                <li>Rust-resistant frame</li>
                <li>Durable UV and weather-resistant construction</li>
                <li>Shell seat features water draining recess</li>
                <li>Shell and base are stackable for transport</li>
                <li>Choice of monochrome finishes and colourways</li>
                <li>Designed by Nagi</li>
              </ul>
            </div>
          </div>

          <div class="tab-pane fade" id="reviews_tab" role="tabpanel">
            <div class="average_area">
              <h4 class="reviews_tab_title">Average Ratings</h4>
              <div class="row align-items-center">
                <div class="col-md-5 order-last">
                  <div class="average_rating_text">
                    <ul class="rating_star ul_li_center">
                      <li><i class="fas fa-star"></i></li>
                      <li><i class="fas fa-star"></i></li>
                      <li><i class="fas fa-star"></i></li>
                      <li><i class="fas fa-star"></i></li>
                      <li><i class="fas fa-star-half-alt"></i></li>
                    </ul>
                    <p class="mb-0">
                      Average Star Rating: <span>4.5 out of 5</span> (2 vote)
                    </p>
                  </div>
                </div>
                <div class="col-md-7">
                  <div class="product_ratings_progressbar">
                    <ul class="five_star ul_li">
                      <li><span>5 Star</span></li>
                      <li><div class="progress_bar"></div></li>
                      <li>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                      </li>
                    </ul>
                    <ul class="four_star ul_li">
                      <li><span>4 Star</span></li>
                      <li><div class="progress_bar"></div></li>
                      <li>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                      </li>
                    </ul>
                    <ul class="three_star ul_li">
                      <li><span>3 Star</span></li>
                      <li><div class="progress_bar"></div></li>
                      <li>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                      </li>
                    </ul>
                    <ul class="two_star ul_li">
                      <li><span>2 Star</span></li>
                      <li><div class="progress_bar"></div></li>
                      <li>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                      </li>
                    </ul>
                    <ul class="one_star ul_li">
                      <li><span>1 Star</span></li>
                      <li><div class="progress_bar"></div></li>
                      <li>
                        <i class="fas fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="customer_reviews">
              <h4 class="reviews_tab_title">2 reviews for this product</h4>
              <div class="customer_review_item clearfix">
                <div class="customer_image">
                  <img src="assets/images/team/team_1.jpg" alt="Getyootech - Gadgets Ecommerce Site Template">
                </div>
                <div class="customer_content">
                  <div class="customer_info">
                    <ul class="rating_star ul_li">
                      <li><i class="fas fa-star"></i></li>
                      <li><i class="fas fa-star"></i></li>
                      <li><i class="fas fa-star"></i></li>
                      <li><i class="fas fa-star"></i></li>
                      <li><i class="fas fa-star-half-alt"></i></li>
                    </ul>
                    <h4 class="customer_name">Aonathor troet</h4>
                    <span class="comment_date">August 2, 2023</span>
                  </div>
                  <p class="mb-0">
                    Nice Product, I got one in black. Goes with anything!
                  </p>
                </div>
              </div>

              <div class="customer_review_item clearfix">
                <div class="customer_image">
                  <img src="assets/images/team/team_2.jpg" alt="Getyootech - Gadgets Ecommerce Site Template">
                </div>
                <div class="customer_content">
                  <div class="customer_info">
                    <ul class="rating_star ul_li">
                      <li><i class="fas fa-star"></i></li>
                      <li><i class="fas fa-star"></i></li>
                      <li><i class="fas fa-star"></i></li>
                      <li><i class="fas fa-star"></i></li>
                      <li><i class="fas fa-star-half-alt"></i></li>
                    </ul>
                    <h4 class="customer_name">Danial obrain</h4>
                    <span class="comment_date">August 2, 2023</span>
                  </div>
                  <p class="mb-0">
                    Great product quality, Great Design and Great Service.
                  </p>
                </div>
              </div>
            </div>

            <div class="customer_review_form">
              <h4 class="reviews_tab_title">Add a review</h4>
              <p>
                Your email address will not be published. Required fields are marked *
              </p>
              <form action="#">
                <div class="form_item">
                  <input type="text" name="name" placeholder="Your name*">
                </div>
                <div class="form_item">
                  <input type="email" name="email" placeholder="Your Email*">
                </div>
                <div class="checkbox_item">
                  <input id="save_checkbox" type="checkbox">
                  <label for="save_checkbox">Save my name, email, and website in this browser for the next time I comment.</label>
                </div>
                <div class="your_ratings">
                  <h5>Your Ratings:</h5>
                  <button type="button"><i class="fa-solid fa-star"></i></button>
                  <button type="button"><i class="fa-solid fa-star"></i></button>
                  <button type="button"><i class="fa-solid fa-star"></i></button>
                  <button type="button"><i class="fa-solid fa-star"></i></button>
                  <button type="button"><i class="fa-solid fa-star"></i></button>
                </div>
                <div class="form_item">
                  <textarea name="comment" placeholder="Your Review*"></textarea>
                </div>
                <button type="submit" class="btn btn_primary">Submit Now</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="newsletter_section">
    <div class="container">
      <div class="row align-items-center">
        <div class="col col-lg-6">
          <h2 class="newsletter_title text-white">
            <i class="icofont-paper-plane"></i>
            Sign Up for Newsletter
          </h2>
        </div>
        <div class="col col-lg-6">
          <form action="#!">
            <div class="newsletter_form">
              <input type="email" name="email" placeholder="Enter your email address">
              <button type="submit" class="btn btn_danger">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</main>
<footer class="footer_section">
        <div class="footer_widget_area">
          <div class="container">
            <div class="row">
              <div class="col col-lg-2 col-md-3 col-sm-6">
                <div class="footer_widget footer_useful_links">
                  <h3 class="footer_widget_title text-uppercase">Quick Links</h3>
                  <ul class="ul_li_block">
                    <li><a href="/register">Sign In</a></li>
                    <!-- <li><a href="/register">Sign Up</a></li> -->
                  </ul>
                </div>
              </div>

              <div class="col col-lg-2 col-md-3 col-sm-6">
                <div class="footer_widget footer_useful_links">
                  <h3 class="footer_widget_title text-uppercase">Custom area</h3>
                  <ul class="ul_li_block">
                    <li><a href="/account">My Account</a></li>
                    <li><a href="/cart">My Cart</a></li>
                  </ul>
                </div>
              </div>

              <div class="col col-lg-8 col-md-6 col-sm-12">
                <div class="footer_widget footer_useful_links">
                  <h3 class="footer_widget_title text-uppercase">contact us</h3>
                  <ul class="ul_li_block">
                    <li>Company Name： INFORMATICA GH SOLUCIONES, S.A. DE C.V.</li>
                    <li>E-mail: hungrybulo@outlook.com</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <el-dialog
        title=""
        :visible.sync="dialogVisible"
        width="30%">
        <span style="font-weight: bold;font-size: 16px;">Added to Cart Successfully</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">continue shopping</el-button>
          <el-button type="primary" @click="$router.push('/cart')">Go to Shopping Cart</el-button>
        </span>
      </el-dialog>

</div>
</template>

<script>
import {
  apiFrontProductDetail, apiFrontCartSave, apiFrontCartCount
} from '@/api/goods'
export default {
  data() {
    return {
      goodsData: {},
      page: {
        type: 'sum',
        numType: true
      },
      pageData: {},
      imgSrc: '',
      Uid: '',
      dialogVisible: false,
      shuliang: ''
    }
  },
  mounted() {
    console.log(this.$route.query.img);
    
    this.Uid = this.$route.query.id
    this.imgSrc = this.$route.query.img
    this.init()
    // this.gouwuche()
  },
  methods: {
    jianshao() {
      var input = document.querySelector('.input_number')
      if (input.value === '0') {
        document.querySelector('.input_number').value++
      }
    },
    tiaozhuanAccount() {
      if (localStorage.getItem('token')) {
        this.$router.push('/cart')
      } else {
        this.$router.push('/register')
        const h = this.$createElement;
        this.$notify({
          title: 'please log in',
          message: h('i', { style: 'color: red'}, 'You have not logged into your account')
        });
      }
    },
    tiaozhuanAccount2() {
      if (localStorage.getItem('token')) {
        this.$router.push('/account')
      } else {
        this.$router.push('/register')
        const h = this.$createElement;
        this.$notify({
          title: 'please log in',
          message: h('i', { style: 'color: red'}, 'You have not logged into your account')
        });
      }
    },
    init() {
      apiFrontProductDetail(this.$route.query.id).then(res => {
        this.goodsData = res.data
        setTimeout(() => {
          const script = document.createElement('script');
          script.src = 'assets/js/main.js';
          document.body.appendChild(script);
        }, 1000);
      })
    },
    clickAdd(){
      var data = {
        cartNum: document.querySelector('.input_number').value,
        productId: this.$route.query.id,
        productAttrUnique: this.goodsData.productValue.默认.id
      }
      apiFrontCartSave(data).then(res => {
        this.dialogVisible = true
        // this.$message.success('Added to Cart Successfully')
      }).catch((err) => {
        console.log(err)
        this.$message.error('Failed to Add to Cart')
      })
    },
    gouwuche() {
      apiFrontCartCount(this.page).then(res => {
        this.pageData = res.data
      })
    }
  }
}
</script>

<style>

</style>